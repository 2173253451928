import React from "react";
import Navbar from "./Navbar";
import Footer from "../components/Footer.tsx";
import { motion } from "framer-motion";
import { FaFlask, FaRobot, FaChartLine, FaUsers, FaGlobe } from "react-icons/fa";
import { RiTimerFlashLine } from "react-icons/ri";
import { IoMdAnalytics } from "react-icons/io";

const ResearchCentersPage = ({ activeLink, setActiveLink }) => {
  return (
    <div className="bg-gray-50 w-full">
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="pt-24 px-4 md:px-12 lg:px-24"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-6xl font-bold text-gray-800 mb-6">
              Accelerate Your Research with{" "}
              <span className="text-emerald-500">AI-Powered Insights</span>
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Transform your research center's capabilities with EderSpark's advanced AI tools designed for cutting-edge scientific discovery
            </p>
          </motion.div>

          {/* Benefits Grid */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
          >
            {[
              {
                icon: <FaFlask className="w-8 h-8" />,
                title: "Advanced Research Tools",
                description: "Access cutting-edge AI models specifically designed for scientific research and analysis"
              },
              {
                icon: <FaRobot className="w-8 h-8" />,
                title: "AI-Powered Analysis",
                description: "Leverage machine learning to identify patterns and insights across vast datasets"
              },
              {
                icon: <IoMdAnalytics className="w-8 h-8" />,
                title: "Data-Driven Insights",
                description: "Transform complex research data into actionable insights quickly and efficiently"
              },
              {
                icon: <RiTimerFlashLine className="w-8 h-8" />,
                title: "Accelerated Discovery",
                description: "Reduce research time with automated literature review and data analysis"
              },
              {
                icon: <FaGlobe className="w-8 h-8" />,
                title: "Global Collaboration",
                description: "Connect with research partners worldwide through our integrated platform"
              },
              {
                icon: <FaUsers className="w-8 h-8" />,
                title: "Team Integration",
                description: "Seamlessly integrate our tools across your entire research team"
              }
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 * index }}
                className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="text-emerald-500 mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-bold text-gray-800 mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* Statistics Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="bg-emerald-50 p-12 rounded-2xl mb-16"
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { number: "200M+", label: "Research Papers" },
                { number: "60%", label: "Time Saved" },
                { number: "24/7", label: "AI Support" }
              ].map((stat, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.4 + (index * 0.1) }}
                  className="text-center"
                >
                  <div className="text-4xl font-bold text-emerald-600 mb-2">{stat.number}</div>
                  <div className="text-gray-600">{stat.label}</div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center bg-white p-12 rounded-2xl mb-16 shadow-md"
          >
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Ready to Transform Your Research Capabilities?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Join leading research centers in revolutionizing scientific discovery with EderSpark
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-emerald-500 text-white px-8 py-3 rounded-lg font-bold hover:bg-emerald-600 transition-colors"
              onClick={() => window.open("/?page=Contact", "_self")}
            >
              Schedule a Demo
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default ResearchCentersPage; 