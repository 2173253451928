import './App.css';
import { Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Landing from './pages/Landing';
import PaymentForm from './components/Checkout';
import CookiesPolicyPage from './pages/CookiesPolicyPage';
import TermsPage from './pages/TermsPage';
import DisclaimerPage from './pages/DisclaimerPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import PaymentPage from './pages/PaymentPage';
import Dashboard from './pages/Dashboard';
import PricingPage from './pages/PricingPage';
import AboutPage from './pages/AboutPage';
import UniversitiesPage from './pages/UniversitiesPage';
import ResearchCentersPage from './pages/ResearchCentersPage';
import EnterprisesPage from './pages/EnterprisesPage';


export const MainContext = React.createContext(null);


function App() {
  const [activeLink, setActiveLink] = React.useState("Home");

  // Read param "page" from URL
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const page = urlParams.get('page');
    if (page) {
      setActiveLink(page);
    }
    console.log("activeLink", activeLink);
  }, []);

  return (
    <>
    <MainContext.Provider value={null}>
      <Routes>
        <Route path="*" element={
          activeLink === "Home" ? <Home activeLink={activeLink} setActiveLink={setActiveLink} /> 
          : activeLink === "Contact" ? <Contact activeLink={activeLink} setActiveLink={setActiveLink} /> 
          : activeLink === "Pricing" ? <PricingPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "About" ? <AboutPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "terms" ? <TermsPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "cookie-policy" ? <CookiesPolicyPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "privacy-policy" ? <PrivacyPolicyPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "disclaimer" ? <DisclaimerPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "eeaaiioouu" ? <Dashboard activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "Universities" ? <UniversitiesPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "ResearchCenters" ? <ResearchCentersPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : activeLink === "Enterprises" ? <EnterprisesPage activeLink={activeLink} setActiveLink={setActiveLink} />
          : <Landing activeLink={activeLink} setActiveLink={setActiveLink} /> } />
       </Routes>
    </MainContext.Provider>
    </>
  );
}

// <Route path="/payment" element={<PaymentPage activeLink={activeLink} setActiveLink={setActiveLink} />} />

export default App;
