import React from "react";
import Navbar from "./Navbar";
import Footer from "../components/Footer.tsx";
import { motion } from "framer-motion";
import { FaIndustry, FaRobot, FaChartLine, FaUsers, FaGlobe, FaHandshake } from "react-icons/fa";
import { RiTimerFlashLine } from "react-icons/ri";
import { IoMdAnalytics } from "react-icons/io";
import { BsShieldCheck } from "react-icons/bs";

const EnterprisesPage = ({ activeLink, setActiveLink }) => {
  return (
    <div className="bg-gray-50 w-full">
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      
      {/* Hero Section - Similar structure to UniversitiesPage.jsx */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="pt-24 px-4 md:px-12 lg:px-24"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-6xl font-bold text-gray-800 mb-6">
              Drive Innovation with{" "}
              <span className="text-emerald-500">Enterprise-Grade AI Research</span>
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Empower your R&D teams with EderSpark's advanced AI tools to accelerate innovation and maintain competitive advantage
            </p>
          </motion.div>

          {/* Benefits Grid */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
          >
            {[
              {
                icon: <FaIndustry className="w-8 h-8" />,
                title: "Industry-Specific Insights",
                description: "Access tailored research insights relevant to your industry vertical"
              },
              {
                icon: <FaRobot className="w-8 h-8" />,
                title: "AI-Driven R&D",
                description: "Accelerate research and development with advanced AI analysis tools"
              },
              {
                icon: <BsShieldCheck className="w-8 h-8" />,
                title: "Enterprise Security",
                description: "Benefit from enterprise-grade security and data protection measures"
              },
              {
                icon: <RiTimerFlashLine className="w-8 h-8" />,
                title: "Rapid Innovation",
                description: "Cut research time by up to 60% with automated analysis and insights"
              },
              {
                icon: <FaHandshake className="w-8 h-8" />,
                title: "Dedicated Support",
                description: "Get priority access to our customer success and technical support teams"
              }
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 * index }}
                className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="text-emerald-500 mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-bold text-gray-800 mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* Enterprise Features Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="bg-white p-12 rounded-2xl mb-16 shadow-md"
          >
            <h2 className="text-3xl font-bold text-gray-800 mb-8 text-center">Enterprise Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                "Volume-based Licensing",
                "Custom Training Sessions",
                "Priority Support",
                "Collaboration opportunities"
              ].map((feature, index) => (
                <div key={index} className="flex items-center gap-3">
                  <div className="text-emerald-500">
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
                      <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <span className="text-gray-700">{feature}</span>
                </div>
              ))}
            </div>
          </motion.div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center bg-emerald-50 p-12 rounded-2xl mb-16"
          >
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Ready to Transform Your Enterprise Research?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Join industry leaders in leveraging AI-powered research for competitive advantage
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-emerald-500 text-white px-8 py-3 rounded-lg font-bold hover:bg-emerald-600 transition-colors"
              onClick={() => window.open("/?page=Contact", "_self")}
            >
              Schedule a Demo
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default EnterprisesPage; 