import React from "react";
import { Box, Flex, Heading, Text, Button, Stack } from "@chakra-ui/react";
import { motion } from "framer-motion";
import sparkaiLanding from "../assets/sparkai-landing.png";
import { IoTimerOutline } from "react-icons/io5";
import { RiTimerFlashLine } from "react-icons/ri";


const MotionBox = motion(Box); // Framer Motion applied to Box
const MotionHeading = motion(Heading); // Framer Motion applied to Heading

const HeroSection = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, delay: 0.3 }}
      className="flex flex-col lg:flex-row items-center justify-center w-full h-full gap-[30px] md:gap-[50px] px-4 md:px-6 mt-[20px]"
    >
      <motion.div
        initial={{ opacity: 0, x: -50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.5 }}
        className="flex flex-col items-center justify-center gap-6 w-full lg:w-[60%] text-center lg:text-justify"
      >
        <div className="flex flex-row items-center justify-center">
          <span className="text-gray-600 font-bold text-5xl md:text-6xl lg:text-8xl mt-[6px]">
            <RiTimerFlashLine 
              className="text-[45px] md:text-[95px] lg:text-[115px]"
            />
          </span>
          <span className="text-gray-600 font-bold text-4xl md:text-6xl lg:text-8xl">
            utpace Time
          </span>
        </div>
        <span className="text-emerald-400 font-bold text-xl md:text-2xl lg:text-3xl">
          EderSpark delivers lightning-fast research
        </span>
        <span className="text-gray-700 text-sm md:text-base w-full md:w-[80%] lg:w-[60%]">
          Stop wasting time on endless searches. EderSpark delivers <span className="text-emerald-500 font-bold">precise insights from houndred of millions of papers instantly</span>, freeing you to focus on creativity and innovation. Let us handle the groundwork so you can drive your ideas forward.
        </span>
      </motion.div>
      <motion.img 
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.6, delay: 0.7 }}
        src={sparkaiLanding} 
        alt="SparkAI Landing" 
        className="w-[80%] md:w-[60%] lg:w-[40%] lg:ml-[-150px] mx-auto" 
      />
    </motion.div>
  );
};

export default HeroSection;
