import React from "react";
import Navbar from "../pages/Navbar.jsx";
import Footer from "../components/Footer.tsx";
import ThreeTierPricing from "../components/PricingSection.jsx";


const PricingPage = ({ activeLink, setActiveLink }) => {
  return (
    <div
      className="bg-gray-50 w-full"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
        <div
            className="mt-10"
        >
            <ThreeTierPricing />
        </div>
      <Footer />
    </div>
  );
};

export default PricingPage;
