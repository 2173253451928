import React, { useState, useEffect } from "react";
import { IconButton } from "@chakra-ui/react";
import { FaBars, FaTimes } from "react-icons/fa";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

import EdersparkLogo from "../assets/ederspark-logo.png";
import { PiStudent } from "react-icons/pi";
import { SlChemistry } from "react-icons/sl";
import { LuBuilding } from "react-icons/lu";
import { SiX } from "react-icons/si";
import { LuBookOpen } from "react-icons/lu";

import { BeakerIcon } from "@heroicons/react/24/solid";

const Navbar = ({activeLink, setActiveLink}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [visible, setVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    let timeoutId;
    
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrolled = currentScrollY > 0;
      
      if (currentScrollY < lastScrollY) {
        // Scrolling up - show immediately
        setVisible(true);
      } else if (currentScrollY > lastScrollY && currentScrollY > 80) {
        // Scrolling down - hide immediately
        setVisible(false);
        
        // After 1.5 seconds, show the navbar again
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setVisible(true);
        }, 1500);
      }
      
      setScrolled(isScrolled);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [lastScrollY]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const setLink = (link) => {
    setActiveLink(link);
  }

  // Menu items
  const pages = [
    { name: "Home", path: "/home" },
    { name: "Tools", path: "/" },
    { name: "Contact", path: "/contact" },
    { name: "Platform", path: "/platform" }
  ];

  return (
    <div
      className={`w-full h-auto flex items-center justify-center bg-gray-50 text-gray-700 fixed top-0 z-50 transition-all duration-300 transform ${
        visible ? 'translate-y-0' : '-translate-y-full'
      } ${scrolled ? 'drop-shadow-sm' : ''}`}
    >
      {/* Navbar */}
      <nav className="flex flex-row w-10/12 items-center justify-between flex-wrap p-4">
        {/* Logo */}
        <img 
          onClick={() => window.open("/", "_self")}
          className="cursor-pointer w-48"
          src={EdersparkLogo} 
          alt="Ederspark Logo" 
        />

        {/* Desktop Navigation Links */}
        <ul className="hidden md:flex gap-6 list-none items-center relative">
          {/* Home Link */}
          <li
            className={`cursor-pointer ${activeLink === "Home" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
            onClick={() => setLink("Home")}
          >
            Home
          </li>

          {/* Our tools dropdown */}
          <li className="relative group">
            <div
              className={`cursor-pointer ${activeLink === "Our tool" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400 flex items-center`}
            >
              Our Tools
              <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            <ul className="absolute hidden group-hover:block bg-white border rounded-md shadow-lg py-2 w-56 z-50 text-sm">
              <li
                className="px-4 py-2 hover:bg-orange-100 cursor-pointer flex items-center gap-2"
                onClick={() => setLink("Universities")}
              >
                <PiStudent className="h-5 w-5" />
                For Universities
              </li>
              <li
                className="px-4 py-2 hover:bg-orange-100 cursor-pointer flex items-center gap-2"
                onClick={() => setLink("ResearchCenters")}
              >
                <SlChemistry className="h-5 w-5" />
                For Research Centers
              </li>
              <li
                className="px-4 py-2 hover:bg-orange-100 cursor-pointer flex items-center gap-2"
                onClick={() => setLink("Enterprises")}
              >
                <LuBuilding className="h-5 w-5" />
                For Enterprises
              </li>
              <li
                className="px-4 py-2 hover:bg-orange-100 cursor-pointer flex items-center gap-2"
                onClick={() => setLink("HowItWorks")}
              >
                <LuBookOpen className="h-5 w-5" />
                How It Works
              </li>
            </ul>
          </li>

          {/* Pricing Link */}
          <li
            className={`cursor-pointer ${activeLink === "Pricing" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
            onClick={() => setLink("Pricing")}
          >
            Pricing
          </li>

          {/* About Link */}
          <li
            className={`cursor-pointer ${activeLink === "About" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
            onClick={() => setLink("About")}
          >
            About
          </li>

          {/* Contact Link */}
          <li
            className={`cursor-pointer ${activeLink === "Contact" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
            onClick={() => setLink("Contact")}
          >
            Contact
          </li>

          {/* Platform Link */}
          <li
            className={`cursor-pointer ${activeLink === "Platform" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
            onClick={() => setLink("Platform")}
          >
            <div className="flex flex-row items-center">
              <BeakerIcon className="h-5 w-5" />
              <span className="ml-2">
                Platform
              </span>
            </div>
          </li>
        </ul>
        <div className="flex items-center gap-4">
                {/* Social Media Icons */}
                <div className="hidden md:block md:ml-auto md:gap-3">
                    <a
                    href="https://www.linkedin.com/company/ederspark"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="LinkedIn"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <FaLinkedin />
                    </IconButton>
                    </a>
                    <a
                    href="https://x.com/EderSpark"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="Twitter"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <SiX />
                    </IconButton>
                    </a>
                    <a
                    href="https://www.tiktok.com/@ederspark"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="TikTok"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <FaTiktok />
                    </IconButton>
                    </a>
                    <a
                    href="https://www.instagram.com/ederspark/"
                    target="_blank"
                    rel="noopener noreferrer"
                    >
                    <IconButton
                        variant="ghost"
                        aria-label="Instagram"
                        color="gray.700"
                        _hover={{ color: "orange.400" }}
                        fontSize="20px"
                    >
                        <AiFillInstagram />
                    </IconButton>
                    </a>
                </div>
        </div>

        {/* Hamburger Icon (Mobile Only) */}
        <IconButton
          className="block md:hidden ml-auto"
          onClick={toggleMenu}
          variant="ghost"
          aria-label="Toggle Navigation"
        >
            {isOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </IconButton>

        {/* Add Mobile Menu */}
        {isOpen && (
          <div className="md:hidden fixed inset-0 top-[70px] bg-gray-50 z-40 h-screen">
            <div className="flex flex-col justify-between h-full">
              <ul className="flex flex-col p-4 space-y-4 bg-gray-50">
                <li
                  className={`cursor-pointer ${activeLink === "Home" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
                  onClick={() => {
                    setLink("Home");
                    setIsOpen(false);
                  }}
                >
                  Home
                </li>

                {/* Mobile Our Tools Section */}
                <li className="space-y-2">
                  <div className={`cursor-pointer ${activeLink === "Our tool" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}>
                    Our Tools
                  </div>
                  <ul className="pl-4 space-y-2">
                    <li
                      className="flex items-center gap-2 py-2"
                      onClick={() => {
                        setLink("Universities");
                        setIsOpen(false);
                      }}
                    >
                      <PiStudent className="h-5 w-5" />
                      For Universities
                    </li>
                    <li
                      className="flex items-center gap-2 py-2"
                      onClick={() => {
                        setLink("ResearchCenters");
                        setIsOpen(false);
                      }}
                    >
                      <SlChemistry className="h-5 w-5" />
                      For Research Centers
                    </li>
                    <li
                      className="flex items-center gap-2 py-2"
                      onClick={() => {
                        setLink("Enterprises");
                        setIsOpen(false);
                      }}
                    >
                      <LuBuilding className="h-5 w-5" />
                      For Enterprises
                    </li>
                    <li
                      className="flex items-center gap-2 py-2"
                      onClick={() => {
                        setLink("HowItWorks");
                        setIsOpen(false);
                      }}
                    >
                      <LuBookOpen className="h-5 w-5" />
                      How It Works
                    </li>
                  </ul>
                </li>

                {/* Other Mobile Menu Items */}
                {["Pricing", "About", "Contact"].map((item) => (
                  <li
                    key={item}
                    className={`cursor-pointer ${activeLink === item ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
                    onClick={() => {
                      setLink(item);
                      setIsOpen(false);
                    }}
                  >
                    {item}
                  </li>
                ))}

                <li
                  className={`cursor-pointer ${activeLink === "Platform" ? 'font-bold text-emerald-400' : ''} hover:text-emerald-400`}
                  onClick={() => {
                    setLink("Platform");
                    setIsOpen(false);
                  }}
                >
                  <div className="flex flex-row items-center">
                    <BeakerIcon className="h-5 w-5" />
                    <span className="ml-2">Platform</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
