import SparkAILogo from "../assets/sparkai_new_only_logo.png";
import FocusLogo from "../assets/focus_only_logo_upscaled.png";
import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import {Media, Video } from '@vidstack/player-react';
import SparkAIVideo from "../assets/cursorful-video-sparkai.mp4";
import SemanticScholar from "../assets/Semantic_Scholar_logo.svg.png";
import CiteExample from "../assets/cite_example.png";
import OrganicConv from "../assets/organic_conv.png";


function SparkAITool() {
    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center justify-center gap-4 w-full h-full gap-6"
        >
            <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-emerald-50 border-2 border-emerald-300 text-emerald-400 text-2xl font-bold py-2 px-4 rounded-3xl hover:bg-emerald-300 hover:text-white transition duration-300"
                onClick={() => {
                    window.open("/?page=platform", "_blank");
                }}
            >
                Try our <span className="text-emerald-700">Platform</span> now!
            </motion.button>
            <h1 className="text-gray-600 font-bold text-2xl md:text-3xl text-center px-4">
                How it works? <span className="text-base font-normal text-gray-400">1 min 6 secs</span>
            </h1>
            <div className="w-full md:w-[60%]">
                <VideoComponent />
            </div>
            <motion.div 
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-[95%] md:w-[90%] lg:w-[80%] mt-8 px-4"
            >
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                    whileHover={{ y: -5 }}
                    className="flex flex-col items-center justify-start gap-4 text-center border-2 border-emerald-500 bg-white p-6 rounded-lg"
                >
                    <svg
                        className="w-16 h-16 text-emerald-500 bg-emerald-100 p-2 rounded-full mt-[-55px] border-2 border-emerald-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                    <h3 className="text-gray-700 font-bold text-xl">
                        Extensive corpus, lightning fast
                    </h3>
                    <span className="text-white bg-emerald-400 font-bold text-4xl p-2 rounded-lg">
                        200M+ <span className="text-white text-lg">PAPERS</span>
                    </span>
                    <p className="text-gray-600 text-sm">
                        Get instant fact-checked answers from millions of research papers in seconds
                    </p>
                    <img src={SemanticScholar} alt="Semantic Scholar" className="w-[200px] md:w-[300px]" />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                    whileHover={{ y: -5 }}
                    className="flex flex-col items-center justify-start gap-4 text-center border-2 border-emerald-500 bg-white p-6 rounded-lg"
                >
                    <svg
                        className="w-16 h-16 text-emerald-500 bg-emerald-100 p-2 rounded-full mt-[-55px] border-2 border-emerald-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h3 className="text-gray-700 font-bold text-xl">
                        All answers are fact-checked
                    </h3>
                    <span className="text-white bg-emerald-400 font-bold text-4xl p-2 rounded-lg">
                        Everything <span className="text-white text-lg">CITED</span>
                    </span>
                    <p className="text-gray-600 text-sm">
                        Our AI is trained to give cites from papers for every answer, ensuring accuracy and reliability
                    </p>
                    <img src={CiteExample} alt="Cite Example" className="w-[200px] md:w-[300px] border-2 border-emerald-500 rounded-lg" />
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    viewport={{ once: true }}
                    whileHover={{ y: -5 }}
                    className="flex flex-col items-center justify-start gap-4 text-center border-2 border-emerald-500 bg-white p-6 rounded-lg"
                >
                    <svg
                        className="w-16 h-16 text-emerald-500 bg-emerald-100 p-2 rounded-full mt-[-55px] border-2 border-emerald-500"
                        fill="none" 
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                    <h3 className="text-gray-700 font-bold text-xl">
                        Natural Conversations
                    </h3>
                    <span className="text-white bg-emerald-400 font-bold text-4xl p-2 rounded-lg">
                        Human-like <span className="text-white text-lg">CHAT</span>
                    </span>
                    <p className="text-gray-600 text-sm">
                        Have organic conversations with our AI that feels natural and understands context
                    </p>
                    <img src={OrganicConv} alt="Organic Conversation" className="w-[200px] md:w-[300px] border-2 border-emerald-500 rounded-lg" />
                </motion.div>
            </motion.div>
        </motion.div>
    )
}

function FocusTool() {
    return (
        <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center justify-center gap-4 w-full h-full gap-6"
        >
            <motion.button 
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="bg-purple-50 border-2 border-purple-300 text-purple-400 text-2xl font-bold py-2 px-4 rounded-3xl hover:bg-purple-300 hover:text-white transition duration-300"
                onClick={() => {
                    window.open("/?page=platform", "_blank");
                }}
            >
                Try our <span className="text-purple-700">Platform</span> now!
            </motion.button>
            <h1 className="text-gray-600 font-bold text-2xl md:text-3xl text-center px-4">
                Video coming soon! <span className="text-base font-normal text-gray-400">Stay tuned</span>
            </h1>
            <motion.div 
                className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-[95%] md:w-[90%] lg:w-[80%] mt-8 px-4"
            >
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                    viewport={{ once: true }}
                    whileHover={{ y: -5 }}
                    className="flex flex-col items-center justify-start gap-4 text-center border-2 border-purple-500 bg-white p-6 rounded-lg"
                >
                    <svg
                        className="w-16 h-16 text-purple-500 bg-purple-100 p-2 rounded-full mt-[-55px] border-2 border-purple-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                    </svg>
                    <h3 className="text-gray-700 font-bold text-xl">
                        Article Specific
                    </h3>
                    <span className="text-white bg-purple-400 font-bold text-4xl p-2 rounded-lg">
                        Focused <span className="text-white text-lg">RESEARCH</span>
                    </span>
                    <p className="text-gray-600 text-sm">
                        Focus on a specific article or topic, and get tailored answers from it
                    </p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    viewport={{ once: true }}
                    whileHover={{ y: -5 }}
                    className="flex flex-col items-center justify-start gap-4 text-center border-2 border-purple-500 bg-white p-6 rounded-lg"
                >
                    <svg
                        className="w-16 h-16 text-purple-500 bg-purple-100 p-2 rounded-full mt-[-55px] border-2 border-purple-500"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <h3 className="text-gray-700 font-bold text-xl">
                        Ask. Analyze. Act
                    </h3>
                    <span className="text-white bg-purple-400 font-bold text-4xl p-2 rounded-lg">
                        Fast <span className="text-white text-lg">INSIGHTS</span>
                    </span>
                    <p className="text-gray-600 text-sm">
                        With Focus, get clear, targeted insights from any paper so you can quickly extract what matters.
                    </p>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    viewport={{ once: true }}
                    whileHover={{ y: -5 }}
                    className="flex flex-col items-center justify-start gap-4 text-center border-2 border-purple-500 bg-white p-6 rounded-lg"
                >
                    <svg
                        className="w-16 h-16 text-purple-500 bg-purple-100 p-2 rounded-full mt-[-55px] border-2 border-purple-500"
                        fill="none" 
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5h12M9 3v2m1.048 9.5A18.022 18.022 0 016.412 9m6.088 9h7M11 21l5-10 5 10M12.751 5C11.783 10.77 8.07 15.61 3 18.129" />
                    </svg>
                    <h3 className="text-gray-700 font-bold text-xl">
                        Knowledge Without Borders
                    </h3>
                    <span className="text-white bg-purple-400 font-bold text-4xl p-2 rounded-lg">
                        Science <span className="text-white text-lg">DEMOCRATIZED</span>
                    </span>
                    <p className="text-gray-600 text-sm">
                        With Focus, language is no obstacle—access and understand research from around the world.
                    </p>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}


function VideoComponent() {
    return (
        <Media className="w-full rounded-3xl">
            <Video loading="visible" controls preload="true">
                <video loading="visible" src={SparkAIVideo} preload="none" data-video="0" controls />
            </Video>
        </Media>
    )
}

export default function ToolsSection() {
    const [activeTool, setActiveTool] = React.useState("SparkAI");
    
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center md:items-start justify-center md:justify-start w-full h-full gap-10 md:gap-20"
        >
            <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="flex flex-col items-center md:items-start justify-center md:justify-start gap-4 md:ml-[20%] mt-8 px-4"
            >
                <div className="flex flex-col items-center justify-center gap-4">
                    <motion.div 
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.4 }}
                        className="flex flex-row items-center justify-center"
                    >
                        <span className="text-gray-600 font-bold text-base">Check out our models</span>
                    </motion.div>
                    <div className="flex flex-row gap-4">
                        <motion.button 
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className={`text-gray-600 font-bold text-lg py-2 rounded-3xl flex items-center justify-center gap-2 hover:bg-emerald-100 border-2 ${activeTool === "SparkAI" ? "bg-emerald-100" : ""} border-emerald-400 transition-colors w-[150px]`}
                            onClick={() => setActiveTool("SparkAI")}
                        >
                            <img src={SparkAILogo} alt="SparkAI Logo" className="w-5 h-5" />
                            SparkAI
                        </motion.button>
                        <motion.button 
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className={`text-gray-600 font-bold text-lg py-2 rounded-3xl flex items-center justify-center gap-2 hover:bg-purple-100 border-2 ${activeTool === "Focus" ? "bg-purple-100" : ""} border-purple-400 transition-colors w-[150px]`}
                            onClick={() => setActiveTool("Focus")}
                        >
                            <img src={FocusLogo} alt="Focus Logo" className="w-5 h-5" />
                            Focus
                        </motion.button>
                    </div>
                </div>
            </motion.div>
            <AnimatePresence mode="wait">
                <motion.div
                    key={activeTool}
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -20 }}
                    transition={{ duration: 0.3 }}
                >
                    {activeTool === "SparkAI" ? <SparkAITool /> : <FocusTool />}
                </motion.div>
            </AnimatePresence>
        </motion.div>
    )
}