import React from "react";
import { motion } from "framer-motion";
import { Box, Flex, Text, Image } from "@chakra-ui/react";

// Import your logos
import UcoLogo from "../assets/ucoLogo.png";
import FacCiencias from "../assets/facCiencias.png";
import ElPatioLogo from "../assets/el-patio-completo-logo.png";
import SemanticScholarLogo from "../assets/Semantic_Scholar_logo.svg.png";

const TrustedLogos = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.6 }}
      className="py-12 bg-gray-50 text-center"
    >
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <text className="text-lg text-gray-500">
          Trusted by
        </text>
      </motion.div>

      <motion.div
        className="flex justify-center items-center gap-8 flex-wrap max-w-7xl mx-auto"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6, delay: 0.2 }}
      >
        {[
          { src: ElPatioLogo, alt: "AOF El Patio Logo", height: "100px" },
          { src: SemanticScholarLogo, alt: "Semantic Scholar Logo", height: "60px" },
        ].map((logo, index) => (
          <motion.div
            key={logo.alt}
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
            whileHover={{ scale: 1.1 }}
          >
            <Image
              src={logo.src}
              alt={logo.alt}
              height={logo.height}
              filter="grayscale(100%)"
              style={{ transition: "filter 0.3s ease" }}
              _hover={{ filter: "grayscale(0%)" }}
            />
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default TrustedLogos;
