import React from "react";
import Navbar from "../pages/Navbar.jsx";
import { Box, Typography, Button } from "@mui/joy";
import HeroSection from "../components/HeroSection.jsx"; 
import TrustedLogos from "../components/TrustedLogosSection.jsx";
import HowItWorksSection from "../components/HowItWorksSection.jsx";
import KeyFeatures from "../components/KeyFeaturesSection.jsx";
import WhyChooseUs from "../components/WhyChooseUsSection.jsx";
import Footer from "../components/Footer.tsx";
import ThreeTierPricing from "../components/PricingSection.jsx";
import ToolsSection from "../pages/ToolsSection.jsx";
import ChatbotBadge from "../components/ChatbotBadge.jsx";
import { motion } from "framer-motion";


const Home = ({ activeLink, setActiveLink }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-gray-50 pt-24 overflow-x-hidden"
    >
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
      >
        <ChatbotBadge />
      </motion.div>
      <HeroSection />
      <ToolsSection />
      <ThreeTierPricing />
      <TrustedLogos />
      <Footer />
    </motion.div>
  );
};

export default Home;
