import React from "react";
import { motion } from "framer-motion";
import { Box, Button, Input, Textarea } from "@chakra-ui/react";
import { FiUser, FiMail, FiMessageSquare, FiPhone, FiMapPin } from "react-icons/fi";

const ContactForm = () => {
  const formControls = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col items-center justify-center w-full min-h-screen p-6 bg-gray-50"
    >
      <motion.div 
        className="flex flex-col md:flex-row items-start justify-between w-full max-w-6xl gap-12 p-8 rounded-2xl"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <motion.div 
          className="w-full md:w-2/5 bg-gradient-to-br from-orange-400 to-red-400 p-8 rounded-xl text-white"
          {...formControls}
        >
          <h2 className="text-3xl font-bold mb-6">Let's get in touch</h2>
          <div className="space-y-6">
            <div className="flex items-center gap-4">
              <FiPhone className="text-xl" />
              <p>+34 678 12 17 09</p>
            </div>
            <div className="flex items-center gap-4">
              <FiMail className="text-xl" />
              <p>contact@ederspark.com</p>
            </div>
            <div className="flex items-center gap-4">
              <FiMapPin className="text-xl" />
              <p>Cordoba, Spain</p>
            </div>
          </div>
        </motion.div>

        <motion.div className="w-full md:w-3/5 space-y-6">
          <motion.h2 
            className="text-3xl font-bold text-gray-800 mb-8"
            {...formControls}
          >
            Send us a message
          </motion.h2>

          <motion.div {...formControls} transition={{ delay: 0.3 }} className="relative">
            <FiUser className="absolute top-3 left-3 text-gray-400" />
            <Input
              placeholder="Your Name"
              className="w-full p-2 pl-10 border border-gray-200 rounded-lg focus:ring-2 focus:ring-orange-400 focus:border-transparent"
            />
          </motion.div>

          <motion.div {...formControls} transition={{ delay: 0.4 }} className="relative">
            <FiMail className="absolute top-3 left-3 text-gray-400" />
            <Input
              placeholder="Your Email"
              type="email"
              className="w-full p-2 pl-10 border border-gray-200 rounded-lg focus:ring-2 focus:ring-orange-400 focus:border-transparent"
            />
          </motion.div>

          <motion.div {...formControls} transition={{ delay: 0.5 }} className="relative">
            <FiMessageSquare className="absolute top-3 left-3 text-gray-400" />
            <Textarea
              placeholder="Your Message"
              className="w-full p-2 pl-10 border border-gray-200 rounded-lg focus:ring-2 focus:ring-orange-400 focus:border-transparent"
              rows={5}
            />
          </motion.div>

          <motion.div 
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            {...formControls}
            transition={{ delay: 0.6 }}
          >
            <Button
              className="w-full bg-gradient-to-r from-orange-400 to-red-400 text-white font-bold py-3 px-6 rounded-lg hover:from-orange-500 hover:to-red-500 transition-all duration-300 shadow-md"
            >
              Send Message
            </Button>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default ContactForm;
