import React from "react";
import Navbar from "./Navbar";
import Footer from "../components/Footer.tsx";
import { motion } from "framer-motion";
import { FaGraduationCap, FaChartLine, FaUsers, FaLightbulb } from "react-icons/fa";
import { RiTimerFlashLine } from "react-icons/ri";

const UniversitiesPage = ({ activeLink, setActiveLink }) => {
  return (
    <div className="bg-gray-50 w-full">
      <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
      
      {/* Hero Section */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="pt-24 px-4 md:px-12 lg:px-24"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-6xl font-bold text-gray-800 mb-6">
              Empower Your Academic Institution with{" "}
              <span className="text-emerald-500">AI-Driven Research</span>
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Transform how your students and faculty conduct research with EderSpark's cutting-edge AI tools
            </p>
          </motion.div>

          {/* Benefits Grid */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16"
          >
            {[
              {
                icon: <FaGraduationCap className="w-8 h-8" />,
                title: "Enhanced Learning Experience",
                description: "Help students quickly understand complex research papers and academic concepts"
              },
              {
                icon: <FaChartLine className="w-8 h-8" />,
                title: "Accelerated Research",
                description: "Enable faculty to conduct literature reviews and research faster than ever"
              },
              {
                icon: <FaUsers className="w-8 h-8" />,
                title: "Institutional Access",
                description: "Provide your entire academic community with powerful research tools"
              },
              {
                icon: <RiTimerFlashLine className="w-8 h-8" />,
                title: "Time Efficiency",
                description: "Save countless hours in research and paper analysis"
              },
              {
                icon: <FaLightbulb className="w-8 h-8" />,
                title: "Innovation Catalyst",
                description: "Foster groundbreaking research and academic excellence"
              }
            ].map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.1 * index }}
                className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
              >
                <div className="text-emerald-500 mb-4">{benefit.icon}</div>
                <h3 className="text-xl font-bold text-gray-800 mb-2">{benefit.title}</h3>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* CTA Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="text-center bg-emerald-50 p-12 rounded-2xl mb-16"
          >
            <h2 className="text-3xl font-bold text-gray-800 mb-6">
              Ready to Transform Your Institution's Research Capabilities?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Join leading universities in revolutionizing academic research with EderSpark
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-emerald-500 text-white px-8 py-3 rounded-lg font-bold hover:bg-emerald-600 transition-colors"
              onClick={() => window.open("/?page=Contact", "_self")}
            >
              Schedule a Demo
            </motion.button>
          </motion.div>
        </div>
      </motion.div>
      <Footer />
    </div>
  );
};

export default UniversitiesPage; 