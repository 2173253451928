import React from "react";
import {
  Box,
  Stack,
  HStack,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { motion } from "framer-motion";

// Reusable component for pricing card wrapper
function PriceWrapper({ children, delay }) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay }}
      viewport={{ once: true }}
    >
      <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: "center", lg: "flex-start" }}
        borderRadius={"xl"}
      >
        {children}
      </Box>
    </motion.div>
  );
}

// Main Pricing Component
export default function ThreeTierPricing() {
  return (
    <div
      className="py-8 sm:py-16 px-4 sm:px-6 md:px-12 text-center flex flex-col gap-4 sm:gap-6 text-gray-800"
    >
      {/* Header */}
      <div
        className="flex flex-col text-2xl sm:text-3xl md:text-4xl text-gray-800 gap-2 sm:gap-4"
      >
        <text className="text-2xl sm:text-3xl md:text-4xl text-gray-800">
          Plans that fit your need
        </text>
        <text className="text-base sm:text-lg text-gray-600">
          Use our platform entirely for free while we are in beta!
        </text>
      </div>

      {/* Pricing Cards */}
      <div
        className="flex flex-col md:flex-row items-center justify-center gap-6 md:gap-4 lg:gap-8 py-4"
      >
        <PriceWrapper delay={0.2}>
          {/* Personal Plan */}
          <div
            className="w-full max-w-sm flex flex-col items-center justify-center border-2 border-orange-300 rounded-xl shadow-md min-h-[24rem]"
          >
            <div 
              className="relative"
            >
              <div
                className="absolute top-[-16px] left-1/2 transform translate-x-[-50%]"
              >
                <text
                  className="text-xs bg-orange-300 text-white px-3 py-1 font-semibold rounded-xl"
                >
                  Free trial!
                </text>
              </div>
              <div 
                className="flex flex-col items-center justify-center py-4 px-12 gap-2"
              >
                <text
                  className="text-2xl font-bold"
                >
                  Personal
                </text>
                <div
                  className="flex flex-row items-center justify-center gap-1"
                justifyContent="center">
                  <text
                    className="text-5xl font-bold"
                  >
                    TBD
                  </text>
                  <text 
                    className="text-3xl font-semibold text-gray-700"
                  >
                    
                  </text>
                  <text 
                    className="text-3xl font-semibold text-gray-500"
                  >
                    /month
                  </text>
                </div>
              </div>
              <div
                className="py-4 rounded-b-xl flex flex-col gap-8"
              >
                <List.Root gap="2" variant="plain" textAlign="start" px={12}>
                  <List.Item>
                      <List.Indicator asChild color="green.500">
                          <FaCheckCircle />
                      </List.Indicator>
                      Access to SparkAI fast model
                  </List.Item>
                </List.Root>
                <div
                  className="flex w-full justify-center rounded-xl"
                >
                  <button
                    className="w-9/12 py-2 rounded-xl bg-orange-300 text-white font-semibold hover:bg-orange-200"
                  onClick={() => window.open("https://ederspark.com/?page=platform", "_self")}>
                      Try it now!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </PriceWrapper>

        <PriceWrapper delay={0.4}>
          {/* Enterprise */}
          <div
            className="w-full max-w-sm flex flex-col items-center justify-center border-2 border-blue-400 rounded-xl shadow-md min-h-[24rem]"
          >
            <div 
              className="relative"
            >
              <div
                className="absolute top-[-16px] left-1/2 transform translate-x-[-50%]"
              >
                <text
                  className="text-xs bg-blue-400 text-white px-3 py-1 font-semibold rounded-xl"
                >
                  Volume discount!
                </text>
              </div>
              <div 
                className="flex flex-col items-center justify-center py-4 px-12 gap-2"
              >
                <text
                  className="text-2xl font-bold"
                >
                  Enterprise / Institution
                </text>
                <div
                  className="flex flex-row items-center justify-center gap-1"
                justifyContent="center">
                  <text
                    className="text-5xl font-bold"
                  >
                    Custom
                  </text>
                </div>
              </div>
              <div
                className="py-4 rounded-b-xl flex flex-col gap-8"
              >
                <List.Root gap="2" variant="plain" textAlign="start" px={12}>
                  <List.Item>
                      <List.Indicator asChild  color="blue.400">
                          <FaCheckCircle />
                      </List.Indicator>
                      Access to SparkAI fast and Pro models
                  </List.Item>
                  <List.Item>
                      <List.Indicator asChild  color="blue.400">
                          <FaCheckCircle />
                      </List.Indicator>
                      First access to Focus
                  </List.Item>
                  <List.Item>
                      <List.Indicator asChild color="blue.400">
                          <FaCheckCircle />
                      </List.Indicator>
                      Best in class models
                  </List.Item>
                  <List.Item>
                      <List.Indicator asChild color="blue.400">
                          <FaCheckCircle />
                      </List.Indicator>
                      Dedicated support
                  </List.Item>
                </List.Root>
                <div
                  className="flex w-full justify-center rounded-xl"
                >
                  <button
                    className="w-9/12 py-2 rounded-xl bg-blue-400 text-white font-semibold hover:bg-blue-200"
                  onClick={() => window.open("https://ederspark.com/?page=Contact", "_self")}>
                      Contact us!
                  </button>
                </div>
              </div>
            </div>
          </div>
        </PriceWrapper>
      </div>
    </div>
  );
}
