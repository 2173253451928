import React from "react";
import { motion } from "framer-motion";
import Navbar from "../pages/Navbar.jsx";
import Footer from "../components/Footer.tsx";
import FoundersPicture from "../assets/founders-pic.jpg";

const AboutPage = ({ activeLink, setActiveLink }) => {
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-gray-50 w-full"
        >
            <Navbar activeLink={activeLink} setActiveLink={setActiveLink} />
            <motion.div 
                className="flex flex-col md:flex-row items-center justify-center gap-12 py-24 px-8 md:px-24"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
            >
                <motion.div 
                    className="w-full md:w-1/2"
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    <motion.img 
                        whileHover={{ scale: 1.05 }}
                        transition={{ duration: 0.3 }}
                        src={FoundersPicture} 
                        alt="Álvaro and Jose, founders of EderSpark" 
                        className="rounded-lg shadow-lg w-full object-cover"
                    />
                </motion.div>
                <motion.div 
                    className="w-full md:w-1/2 flex flex-col gap-6"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                >
                    <h1 className="text-4xl font-bold text-gray-800">About Us</h1>
                    <p className="text-lg text-gray-600 leading-relaxed">
                        We are <strong>Álvaro and Jose</strong>, two passionate individuals who have combined our skills and vision to create <strong>EderSpark</strong>. Our goal is to revolutionize access to scientific information, <strong>making it more efficient and accessible</strong>. We believe in the power of science to drive humanity's progress, and with EderSpark, <strong>we aspire to be a key tool in advancing that mission</strong>.
                    </p>
                </motion.div>
                <motion.div 
                    className="w-full md:w-1/2 flex flex-col gap-6"
                    initial={{ opacity: 0, x: 50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.6, delay: 0.6 }}
                >
                    <h1 className="text-4xl font-bold text-gray-800">Why we created EderSpark</h1>
                    <p className="text-lg text-gray-600 leading-relaxed">
                        At <strong>EderSpark</strong>, we're driven by a shared passion for <strong>science and technology</strong> and a deep desire to make a difference. As researchers ourselves, we've experienced firsthand the challenges of sifting through countless papers, battling information overload, and <strong>struggling to find the right insights quickly</strong>. <br /> <br />

                        We created EderSpark to solve this problem. Our <strong>mission is to empower researchers, students, and innovators</strong>  with a tool that simplifies and accelerates access to scientific knowledge. By leveraging <strong>advanced AI</strong>, we aim to break barriers, eliminate inefficiencies, and make science more accessible to everyone. <br /> <br />

                        EderSpark is more than just a platform—it's our commitment to <strong>supporting the global scientific community</strong> and fostering progress by turning information into inspiration, one query at a time.
                    </p>
                </motion.div>
            </motion.div>
            <Footer />
        </motion.div>
    );
};

export default AboutPage;
